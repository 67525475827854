export function sortBy<T>(source: T[], selector: (item: T) => number | string): T[] {
  return source.sort((left, right) => {
    const a = selector(left);
    const b = selector(right);
    if (nullOrUndefined(a)) {
      if (nullOrUndefined(b)) {
        return 0;
      }
      return 1;
    }
    if (nullOrUndefined(b)) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  });
}

function nullOrUndefined(x: any) {
  return x === null || x === undefined;
}
